
import './App.css';
import * as React from 'react';
import { ScheduleComponent, Agenda, Inject, Resize, DragAndDrop, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective } from '@syncfusion/ej2-react-schedule';
import { DataManager, UrlAdaptor, Query, } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
//import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
//import * as localeTexts from './locale.json';
import { useState } from 'react';
import Login from './login.js';

import { useRef } from 'react';

import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-schedule/styles/material.css";
import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
import "../node_modules/@syncfusion/ej2-icons/styles/material.css";

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/de/ca-gregorian.json'),
  require('cldr-data/main/de/numbers.json'),
  require('cldr-data/main/de/timeZoneNames.json')
);
L10n.load({
  "de": {
    "schedule": {
      "day": "Tag",
      "week": "Woche",
      "workWeek": "Arbeitswoche",
      "month": "Monat",
      "agenda": "Agenda",
      "weekAgenda": "Wochenagenda",
      "workWeekAgenda": "Arbeitswochenagenda",
      "monthAgenda": "Monatsagenda",
      "today": "Heute",
      "noEvents": "keine Veranstaltungen",
      "emptyContainer": "There are no events scheduled on this day.",
      "allDay": "All day",
      "start": "Start",
      "end": "Ende",
      "more": "mehr",
      "close": "Schließen",
      "cancel": "Abbrechen",
      "noTitle": "(Kein Titel)",
      "delete": "Löschen",
      "deleteEvent": "Lösche Veranstaltung",
      "deleteMultipleEvent": "Delete Multiple Events",
      "selectedItems": "Items selected",
      "deleteSeries": "Delete Series",
      "edit": "Edit",
      "editSeries": "Edit Series",
      "editEvent": "Edit Event",
      "createEvent": "Erstellen",
      "subject": "Titel",
      "addTitle": "Mitteilung",
      "moreDetails": "Mehr Details",
      "save": "Speichern",
      "editContent": "Do you want to edit only this event or entire series?",
      "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
      "deleteContent": "Sind Sie sicher, dass Sie diese Eingabe löschen wollen?",
      "deleteMultipleContent": "Are you sure you want to delete the selected events?",
      "newEvent": "Neue Veranstaltung",
      "title": "Titel",
      "location": "Ort",
      "description": "Leitung",
      "timezone": "Zeitzone",
      "startTimezone": "Start Timezone",
      "endTimezone": "End Timezone",
      "repeat": "Repeat",
      "saveButton": "Speichern",
      "cancelButton": "Abbrechen",
      "deleteButton": "Löschen",
      "recurrence": "Recurrence",
      "wrongPattern": "The recurrence pattern is not valid.",
      "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
      "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
      "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
      "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
      "editRecurrence": "Edit Recurrence",
      "repeats": "Repeats",
      "alert": "Alert",
      "startEndError": "The selected end date occurs before the start date.",
      "invalidDateError": "The entered date value is invalid.",
      "ok": "Ok",
      "occurrence": "Occurrence",
      "series": "Series",
      "previous": "Previous",
      "next": "Next",
      "timelineDay": "Timeline Day",
      "timelineWeek": "Timeline Week",
      "timelineWorkWeek": "Timeline Work Week",
      "timelineMonth": "Timeline Month",
      "expandAllDaySection": "Expand",
      "collapseAllDaySection": "Collapse"
    },

  }
});


function App() {

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const handleLogin = (token,username) => {
    setToken(token);
    setUser(username)
  };
   
  return (
    
    
      <Router>
        <div>
        <Routes>
          <Route exact path = "/" 
           element = {token ? <Navigate to = "/calendar" /> : <Login onLogin={handleLogin}/>} />
          <Route path="/calendar"
          element = {token ? <Calendar username={user} /> : <Navigate to = "/"/>}/>
          
          </Routes>
        </div>
      </Router>
    )};
    
    
    
    
    


function Calendar({username}) {
console.log({username});





  const scheduleObj = useRef(null);
  const hostIp = window.location.hostname;
  const dataManagerUrl = '/api/scheduleevents/getData';
  const dataManagerCrudUrl = '/api/scheduleevents/crudActions';
  const dataManager = new DataManager({
    url: dataManagerUrl,
    //'http://localhost:8080/api/scheduleevents/getData','http://192.168.178.51:8080/api/scheduleevents/getData', 
    crudUrl: dataManagerCrudUrl,
    //'http://localhost:8080/api/scheduleevents/crudActions',//'http://192.168.178.51:8080/api/scheduleevents/crudActions', 
    adaptor: new UrlAdaptor(),
    crossDomain: true,

  });

  const [eventFilter, setEventFilter] = useState(dataManager);

  const group = { resources: ['Owners'] };
  const ownerData = [
    { OwnerText: 'Unterkurs', value: 'A', OwnerColor: '#ffaa00' },
    { OwnerText: 'Oberkurs', value: 'B', OwnerColor: '#75be59' },
    { OwnerText: 'Fachleiter', value: 'C', OwnerColor: '#7499e1' },
    { OwnerText: 'Praktikanten', value: 'D', OwnerColor: '#f8a398' },
    { OwnerText: 'Gäste', value: 'E', OwnerColor: '#b42041' },
    { OwnerText: ' ', value: 'F', OwnerColor: '#4d4e6d' },

  ];

if(username === 'fl'){
  var agendaGroupData = [
    { text: 'alle Gruppen anzeigen', value: 'X' },
    { text: 'Unterkurs', value: 'A' },
    { text: 'Oberkurs', value: 'B' },
    { text: 'Fachleiter', value: 'C' },
    { text: 'Praktikanten', value: 'D' },
    { text: 'Gäste', value: 'E' }

  ];
}
else {
   agendaGroupData = [
    { text: 'alle Gruppen anzeigen', value: 'X' },
    { text: 'Unterkurs', value: 'A' },
    { text: 'Oberkurs', value: 'B' },
    { text: 'Praktikanten', value: 'D' },
    { text: 'Gäste', value: 'E' }
  ];
}
  
  const agendaGroupDataFields = { text: 'text', value: 'value' };

  const onDropdownChange = (e) => {
    const query = new Query().addParams("ownerId", e.value)
                            .addParams("username",username);
    dataManager.executeQuery(query).then((res) => {
      //console.log(JSON.stringify(res.result));
      setEventFilter(res.result);
    }).catch((err) => {
      console.error('Fehler beim Abrufen der Daten: ', err)
    });
  }
  const getOwnerGroup = (val) =>{
    if(val !== undefined){
      return ownerData.find(({ value }) => value === val).OwnerText;
    }
  }
  const quickInfoContentTemplate =(props) =>{
 
    return (
      <table className="e-quickinfo-content" style={{ width: '100%', padding: '5', fontSize:'11pt' }}>
  <tbody>
    <tr>
      <td style={{  opacity:'0.5',textAlign:'left'  }} className="e-location e-large e-icons">
      </td>
      <td style={{textAlign:'left',height:'3em'   }}>
      {props.location}
      </td>
    </tr>
    <tr>
      <td style={{textAlign:'left', opacity:'0.5'  }} className="e-clock e-large e-icons">
      </td>
      <td style={{textAlign:'left', height:'3em' }}>
      {props.starttime.toLocaleTimeString('de-DE',{hour:'2-digit',minute:'2-digit'})} - {props.endtime.toLocaleTimeString('de-DE',{hour:'2-digit',minute:'2-digit'})}
      </td>
    </tr>
    <tr>
      <td style={{textAlign:'left', opacity:'0.5'  }} className="e-people e-large e-icons">
      </td>
      <td style={{textAlign:'left', height:'3em'  }} >
      { getOwnerGroup(props.OwnerId)
        }
      </td>
    </tr>
    <tr>
      <td style={{textAlign:'left', opacity:'0.5'  }} className="e-user e-large e-icons">
      </td>
      <td style={{textAlign:'left', height:'3em'  }} >
      {props.description}
      </td>
    </tr>
    <tr>
    <td style={{textAlign:'left', opacity:'0.5'  }} className="e-comment-2 e-large e-icons">
    </td>
    <td style={{textAlign:'left',height:'3em'   }}>
    {props.notice}
    </td>
  </tr>
   
  </tbody>
      </table>
    
    );
  }
  
  return (
    <div className="App">
      <ScheduleComponent
        ref={scheduleObj}
        width='100%'
        height='650px'
        currentView='Agenda'
        agendaDaysCount={28}
        locale='de'
        group={group}
        quickInfoTemplates={{content:quickInfoContentTemplate.bind(this)}}
        readonly={true} 
        eventSettings={{
          dataSource: eventFilter,
          fields: {
            id: 'id',
            subject: { name: 'subject' },
            location: { name: 'location' },
            description: { name: 'description' },
            startTime: { name: 'starttime' },
            endTime: { name: 'endtime' },
            OwnerId: { name: 'OwnerId' }
          }
        }}>

        <DropDownListComponent id="groupViewDropdown" dataSource={agendaGroupData} textField='text' fields={agendaGroupDataFields} placeholder='Gruppe auswählen' idField='value' change={onDropdownChange.bind(this)} />
        <ResourcesDirective>
          <ResourceDirective field='OwnerId' title='Owner' name='Owners' allowMultiple={false} dataSource={ownerData} textField='OwnerText' idField='value' allowGroupEdit={false} colorField='OwnerColor'>
          </ResourceDirective>
        </ResourcesDirective>
        < ViewsDirective >
          <ViewDirective option='Agenda' />
        </ViewsDirective>

        <Inject services={[Agenda, Resize, DragAndDrop]} />
      </ScheduleComponent>
    </div>
  );
}

export default App;
//      <ResourceDirective field='location' title='location' name='Locations' allowMultiple={false} dataSource={locationData} textField='LocationText' idField='location' allowGroupEdit={false} >

//<ResourceDirective field='OwnerId' title='Owner' name='Owners' allowMultiple={false} dataSource={ownerData} textField='OwnerText' idField='Id' allowGroupEdit={true} colorField='OwnerColor'>
//</ResourceDirective>
